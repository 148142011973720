import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const StepsAlthard = props => {
  const { step, steps, highestStep } = props
  return (
    <div id='bk-breadcrumb' className='group'>
      <div className='group'>
        <ul>
          {steps.map(item => <StepAlthard key={item.step}
            isNewKonfiguration={props.isNewKonfiguration}
            wohnungsgrundrissId={props.wohnungsgrundrissId}
            konfigurationVId={props.konfigurationVId}
            highestStep={highestStep}
            currentStep={step} {...item} />)}
        </ul>
      </div>
    </div>
  )
}

StepsAlthard.propTypes = {
  step: PropTypes.number.isRequired,
  highestStep: PropTypes.number,
  wohnungsgrundrissId: PropTypes.number,
  konfigurationVId: PropTypes.number,
  isNewKonfiguration: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.number.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.func,
    description: PropTypes.string
  }))
}

StepsAlthard.defaultProps = {
  isNewKonfiguration: false,
  steps: [
    {
      step: 2,
      icon: '1',
      title: 'Konfiguration',
      description: '',
      link: (konfigurationVId) => '/konfigurator/' + konfigurationVId + '/konfiguration'
    }, {
      step: 3,
      icon: '2',
      title: 'Zusatzoptionen',
      link: (konfigurationVId) => '/konfigurator/' + konfigurationVId + '/zusatzoptionen'
    }, {
      step: 4,
      icon: '3',
      title: 'Zusammenfassung',
      link: (konfigurationVId) => '/konfigurator/' + konfigurationVId + '/zusammenfassung'
    }
  ]
}

export default StepsAlthard

const StepAlthard = props => {
  const { currentStep, step, icon, title, highestStep,
    link, konfigurationVId, wohnungsgrundrissId, isNewKonfiguration } = props
  const myStep = highestStep !== undefined && highestStep > currentStep ? highestStep : currentStep
  const isActive = currentStep >= step || highestStep >= step ? 'active' : ''
  const isLinked = myStep >= step
  const isClickable = isLinked ? ' is-clickable' : ''
  const linkedStep = isLinked
    ? <Link to={link(konfigurationVId, wohnungsgrundrissId, isNewKonfiguration)}>{icon}</Link>
    : icon

  return (
    <li className={isActive + isClickable} title={title}>{linkedStep}</li>
  )
}
